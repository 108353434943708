@import '../common/common.css';

.About-layout {
  width: 100%;
  min-height: 100vh;
  padding-top: 20vmin;
}

.About-banner-caption {
  top: 10vmin;
  left: 0px;
  position: absolute;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: rgba(255, 255, 255, 0.8);
  width: 100%;
}

.About-banner-caption-h0 {
  display: flex;
  align-items: center;

  flex: 2;
  font-size: 4.5vmax;
  position: relative;
  opacity: 0;
  font-weight: bolder;
  text-shadow: #FFFFFF 0px 0px 10px;

  animation-duration: 0.5s;
  animation-name: fadein, slideup;
  animation-fill-mode: forwards;

  transition-duration: 150ms;
  transition-timing-function: ease-out;
}

.About-banner-caption-h0:hover {
  text-shadow: #FFFFFF 0px 0px 50px;
}

.About-content {
  width: 100%;
  font-size: 3vmin;
  color: #FFFFFF;
  line-height: 8vmin;
  text-align: left;

  padding: 10vmin;

  opacity: 0;
  animation-duration: 0.5s;
  animation-name: fadein;
  animation-fill-mode: forwards;
  animation-delay: 0.2s;
}

.About-content p {
  background-color: rgba(0, 0, 0, 0.3);
  padding: 3vmin;
  border-radius: 1vmin;

  box-shadow: 0px 0px 20px rgba(255, 253, 255, 0.3);
  white-space: pre-line;
}
