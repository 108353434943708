@import '../common/common.css';

.Contact-layout {
  width: 100%;
  min-height: 100vh;
  padding-top: 25vmin;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Contact-banner-caption {
  top: 10vmin;
  left: 0px;
  position: absolute;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: rgba(255, 255, 255, 0.8);
  width: 100%;
}

.Contact-banner-caption-h0 {
  display: flex;
  align-items: center;

  flex: 2;
  font-size: 4.5vmax;
  position: relative;
  opacity: 0;
  font-weight: bolder;
  text-shadow: #FFFFFF 0px 0px 10px;

  animation-duration: 0.5s;
  animation-name: fadein, slideup;
  animation-fill-mode: forwards;

  transition-duration: 150ms;
  transition-timing-function: ease-out;
}

.Contact-banner-caption-h0:hover {
  text-shadow: #FFFFFF 0px 0px 50px;
}

.Contact-info {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  opacity: 0;
  animation-duration: 0.5s;
  animation-name: fadein;
  animation-fill-mode: forwards;
  animation-delay: 0.2s;
}

.Contact-details {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 5vmin;
  margin-left: 1vmin;
  height: 100%;

  color: white;
  font-size: 1.5vmax;
}

.Contact-slogan {
  font-size: 2vmax;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 3vmin;
}

.Contact-map {
  height: 60vmin;
  width: 60vmin;
  margin-left: 5vmin;
  margin-right: 1vmin;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
