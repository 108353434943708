@import '../common/common.css';

.Home-layout {
  width: 100%;
}

.Home-banner-caption {
  padding-top: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: rgba(255, 255, 255, 0.8);
  width: 100%;
}

.Home-banner-caption-h0 {
  display: flex;
  align-items: center;

  flex: 2;
  font-size: 4.5vmax;
  font-weight: bolder;
  text-shadow: #FFFFFF 0px 0px 10px;

  opacity: 0;
  position: relative;
  animation-duration: 0.5s;
  animation-name: fadein, slideup;
  animation-fill-mode: forwards;

  transition-duration: 150ms;
  transition-timing-function: ease-out;
}

.Home-banner-caption-h0:hover {
  text-shadow: #FFFFFF 0px 0px 50px;
}

.Home-banner-caption-h1 {
  display: flex;
  align-items: center;

  flex: 1;
  font-size: 3.5vmax;

  opacity: 0;
  position: relative;
  animation-duration: 0.5s;
  animation-name: fadein, slideup;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;
}

.Home-banner-caption-h2 {
  display: flex;
  align-items: center;

  flex: 1;
  font-size: 2.5vmax;

  opacity: 0;
  position: relative;
  animation-duration: 0.5s;
  animation-name: fadein, slideup;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
}

.Home-partners {
  margin-top: 1vmin;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: white;

  opacity: 0;
  position: relative;
  animation-duration: 0.5s;
  animation-name: fadein, slideup;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
}

.Home-partners-caption {
  font-size: 3.5vmax;
  font-weight: bold;
  margin-bottom: 5vh;

  transition-duration: 150ms;
  transition-timing-function: ease-out;
}
