.Partners-layout {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.Partners-item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 30vmin;
  height: 30vmin;
}

.Partners-item img {
  padding: 2vmin;
  background-color: white;
  border-radius: 10%;

  width: 22vmin;
  height: 22vmin;

  transition-duration: 100ms;
  transition-timing-function: ease-out;
}

.Partners-item img:hover {
  transform: scale(1.1);
}
