.Navbar-trans {
  transition-duration: .5s;
  transition-timing-function: ease-out;
}

.Navbar-solid {
  background-color: rgba(0, 0, 0, 0.5);
  transition-duration: .5s;
  transition-timing-function: ease-out;
}

.Navbar-logo {
  display: inline-block;
  vertical-align: middle;
  height: 2vw;
  min-height: 30px;
  margin: 5px;

  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}

.Navbar-logo:hover {
  box-shadow: #FFFFFF 0 0 20px;
}

.Navbar-navlink {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;

  font-size: 1.5vmax;
  font-family: Arial;
  font-weight: bold;
  text-decoration: none;

  margin-left: 1em;
  margin-right: 1em;
  height: 100%;
  color: white;
  transition: .2s;
  transition-timing-function: ease-out;
  border-radius: 2px;
  min-width: 10vw;
  height: 100%;
}

.Navbar-navlink:hover {
  background-color: rgba(255, 255, 255, 0.3);
  color: white;
  text-decoration: none;
  text-shadow: #FFFFFF 0px 0px 5px;
}

.Navbar-navlink-active {
  background-color: rgba(255, 255, 255, 0.3);
  color: white;
  text-decoration: none;
  text-shadow: #FFFFFF 0px 0px 5px;
}
