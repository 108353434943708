@import '../common/common.css';

.Products-layout {
  width: 100%;
  min-height: 100vh;
  padding-top: 30vmin;
}

.Products-banner-img {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: black;

  overflow: hidden;

  width: 100%;
  z-index: 0;
}

.Products-banner-img img {
  filter: brightness(70%);
  filter: blur(5px);
  width: 100%;
  mask-image: linear-gradient(to top, rgba(7, 14, 18, 0) 0%, rgba(7, 14, 18, 0.85) 100%);

  margin: -5px;

  opacity: 0;
  animation-duration: 3s;
  animation-name: fadein;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

.Products-banner-caption {
  top: 10vmin;
  left: 0px;
  position: absolute;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: rgba(255, 255, 255, 0.8);
  width: 100%;
}

.Products-banner-caption-h0 {
  display: flex;
  align-items: center;

  flex: 2;
  font-size: 4.5vmax;
  position: relative;
  font-weight: bolder;
  text-shadow: #FFFFFF 0px 0px 10px;

  opacity: 0;
  animation-duration: 0.5s;
  animation-name: fadein, slideup;
  animation-fill-mode: forwards;

  transition-duration: 150ms;
  transition-timing-function: ease-out;
}

.Products-banner-caption-h0:hover {
  text-shadow: #FFFFFF 0px 0px 50px;
}

.Products-grid-layout {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
