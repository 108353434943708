.page {
  position: relative;
  padding-left: 5vw;
  padding-right: 5vw;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.page-banner-img {
  position: absolute;
  z-index: 0;
  top: 0px;
  left: 0px;

  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: cover;
  mask-image: linear-gradient(to top, rgba(7, 14, 18, 0) 0%, rgba(7, 14, 18, 0.85) 100%);
  filter: blur(5px);

  animation-duration: 1s;
  animation-name: fadein;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
