@import '../common/common.css';

.ProductItemCard {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.7);
  border-radius: 1vmin;
  padding: 2vmin;
  margin: 2vmin;

  opacity: 0;
  animation-duration: 1s;
  animation-name: fadein;
  animation-fill-mode: forwards;

  transition-duration: 200ms;
  transition-timing-function: ease-out;
}

.ProductItemCard:hover {
  transform: scale(1.05);
}

.ProductItemCard-img-container {
  width: 30vmin;
  height: 35vmin;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ProductItemCard img {
  max-width: 25vmin;
  max-height: 25vmin;
  border-radius: 1vmin;
}

.ProductItemCard p {
  font-size: 3vmin;
}
